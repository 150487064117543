<template>
  <b-modal
    id="modal-third-party-integration"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="Third Party Integration"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="updateMerchantForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickApprove)"
        >
          <b-row class="d-flex justify-content-center">
            <b-col cols="7">
              <b-form-group
                label="Merchant"
                label-for="h-merchant"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="merchant"
                >
                  <b-form-input
                    id="merchant"
                    v-model="merchantName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Merchant"
                    type="text"
                    autocomplete="nope"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center">
            <b-col cols="7">
              <b-form-group
                label="Business"
                label-for="h-business"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="business"
                >
                  <b-form-input
                    id="business"
                    v-model="businesstName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Business"
                    type="text"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center">
            <b-col cols="7">
              <b-form-group
                label="User Name"
                label-for="h-user"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="user"
                >
                  <b-form-input
                    id="user"
                    v-model="userName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="User Name"
                    type="text"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center">
            <b-col cols="7">
              <b-form-group
                label="Intergration Type"
                label-for="h-type"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="intergration_type"
                >
                  <b-form-input
                    id="intergration_type"
                    v-model="intergrationType"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Intergration Type"
                    type="text"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center">
            <b-col cols="7">
              <b-form-group
                label="Intergration Status"
                label-for="h-status"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="intergration_status"
                >
                  <b-form-input
                    id="intergration_status"
                    v-model="intergrationStatus"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Intergration Status"
                    type="text"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- submit and reset -->
          <b-row>
            <b-col>
              <div class="d-flex justify-content-center">
                <b-button
                  v-show="isVisible"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="custom-button-color"
                >

                  Approve

                </b-button></div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import {
  tenant,
} from '@/constants/config'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    open: false,
    id: null,
    form: {},
    modalLoading: false,
    loading: false,
    tenant,
    merchantName: null,
    businesstName: null,
    intergrationStatus: null,
    intergrationType: null,
    userName: null,
    isVisible: false,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(merchantId, businessId) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.merchantId = merchantId
      this.businessId = businessId
      this.modalLoading = true
      await this.getMerchantBusinessDetails()
      this.modalLoading = false
    },

    async getMerchantBusinessDetails() {
      try {
        const businessDetails = (await MerchantRepository.getMerchantBusinessDetails(this.merchantId, this.businessId))
        this.businesstName = businessDetails.data.data.business_name
        this.getMerchantDetails()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getMerchantDetails() {
      try {
        const merchantDetails = (await MerchantRepository.getMerchantDetails(this.merchantId))
        this.merchantName = merchantDetails.data.data.name
        this.getMerchantBusinessIntergrationDetailsById()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getMerchantBusinessIntergrationDetailsById() {
      try {
        const getMerchantBusinessIntergrationDetails = (await MerchantRepository.getMerchantBusinessIntergrationDetailsById(this.businessId))
        this.userName = `${getMerchantBusinessIntergrationDetails.data.data[0].merchant_user.first_name}`
        this.intergrationStatus = getMerchantBusinessIntergrationDetails.data.data[0].integration_status
        this.intergrationType = getMerchantBusinessIntergrationDetails.data.data[0].intergration_type.type

        if (this.intergrationStatus === 'pending') {
          this.isVisible = true
        } else {
          this.isVisible = false
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onClickApprove() {
      try {
        const res = await MerchantRepository.approveIntergrationRequest(this.businessId)
        if (res.status === 200) {
          this.isVisible = false
          this.showSuccessMessage('Merchant integration request is approved')
          this.clearForm()
        } else {
          this.showErrorMessage('Failed to send request')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
