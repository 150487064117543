<template>
  <div>
    <b-card
      no-body
    >
      <div>
        <div
          class="m-2"
        >
          <!-- input -->
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-button
                v-can="'create-merchant-business'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="custom-button-color"
                @click="onClickOpenAddMerchantBusinessModal"
              >
                <feather-icon icon="PlusIcon" />
                Add New Business
              </b-button>
            </b-col>
          </b-row>
        </div>

        <!-- table -->
        <b-overlay
          id="overlay-background"
          variant="light"
          opacity="0.30"
          blur="10px"
          rounded="sm"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="rows"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            show-empty
            sticky-header="100vh"
            class="hide-vertical-scroll"
          >
            <template #empty>
              <TableDataFetching
                :rows="rows"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(business_name)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  size="32"
                  :text="avatarText(data.item.business_name)"
                  variant="light-primary"
                />
                <div>
                  <div class="font-weight-bolder ml-1">
                    {{ data.item.business_name }}
                  </div>
                  <div class="font-small-2 ml-1">
                    {{ data.item.ref_no }}
                  </div>
                </div>
              </div>
            </template>

            <template #cell(is_default)="data">
              <b-badge :variant="data.value ?'light-success':'light-danger'">
                {{ data.value ? 'Yes' : 'No' }}
              </b-badge>
            </template>

            <template #cell(created_at)="data">
              <div class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder ml-1">
                    {{ timestampToDateTime(data.item.created_at) }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(is_active)="data">
              {{ data.value }}
              <b-badge :variant="status[1][1]">
                {{ status[0][1] }}
              </b-badge>
            </template>

            <template #cell(action)="data">
              {{ data.value }}
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                dropup
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-can="'show-merchant-business'"
                  @click="onClickOpenManageMerchantDetails(data.item.merchant_id, data.item.id)"
                >
                  <feather-icon icon="EditIcon" />
                  Manage Business Details
                </b-dropdown-item>
                <b-dropdown-item
                  v-can="'create-merchant-business'"
                  @click="onClickMakeDefault(data.item.merchant_id, data.item.id)"
                >
                  <feather-icon icon="ToolIcon" />
                  Set as Primary
                </b-dropdown-item>
                <b-dropdown-item
                  @click="onClickOpenThirdPartyIntegration(data.item.merchant_id, data.item.id)"
                >
                  <feather-icon icon="KeyIcon" />
                  Third Party Integration
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-sm-start"
              >
                <label>Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block ml-1 width-100 mr-1"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                md="9"
                class="d-flex align-items-center justify-content-sm-end"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :total-rows="0"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </div>
    </b-card>

    <create-merchant-business-modal ref="add_new_merchant_business_modal" />
    <third-party-integration-modal ref="third_party_integration_modal" />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BPagination,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { timestampToDateTime } from '@core/utils/timeUtil'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store'
import CreateMerchantBusinessModal from '@/components/merchant/CreateMerchantBusinessModal.vue'
import ThirdPartyIntegrationModal from '@/components/merchant/ThirdPartyIntegrationModal.vue'

const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    avatarText,
    timestampToDateTime,
    filters: {},
    total: 0,
    meta: {},
    dataLoading: false,
    sort: '',
    perPage: 20,
    pageOptions: [10, 20, 50],
    totalRows: 1,
    page: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    fields: [
      {
        key: 'id', label: 'Ref No', sortable: true, thClass: 'd-none', tdClass: 'd-none',
      },
      { key: 'business_name', label: 'Business Name', sortable: true },
      { key: 'is_default', label: 'Primary Business', sortable: false },
      { key: 'created_at', label: 'Created At', sortable: true },
      { key: 'action', label: 'Action', sortable: false },
    ],
    rows: [],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BPagination,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay,
    'v-select': vSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    BAvatar,
    CreateMerchantBusinessModal,
    ThirdPartyIntegrationModal,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    page() {
      this.fetchMerchantBusinessList()
    },
    perPage() {
      this.fetchMerchantBusinessList()
    },
  },
  mounted() {
    // Set the initial number of items
    // this.fetchMerchantBusinessList()
  },
  methods: {
    onClickRefresh() {
      this.fetchMerchantBusinessList()
    },
    onClickOpenAddMerchantBusinessModal() {
      this.$refs.add_new_merchant_business_modal.openModal(this.$route.params.id)
    },
    onClickOpenManageMerchantDetails(id, businessId) {
      this.$router.push({ name: 'merchant-business-details', params: { id, businessId } })
    },
    onClickOpenThirdPartyIntegration(id, businessId) {
      this.$refs.third_party_integration_modal.openModal(id, businessId)
    },
    async fetchMerchantBusinessList() {
      this.dataLoading = true
      this.rows = []
      try {
        const { data } = (await MerchantRepository.getMerchantBusinessList(this.$route.params.id))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async onClickMakeDefault(merchantId, businessId) {
      try {
        const res = await MerchantRepository.setBusinessAsDefault(merchantId, businessId)
        if (res.status === 200) {
          this.onClickRefresh()
          this.showSuccessMessage('Successfully updated business as primary business')
        } else {
          this.showErrorMessage('Failed to set as primary business')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    // REQUIRED
    filterQueryUpdate() {
      this.fetchMerchantBusinessList()
    },
    handleChangePage(page) {
      this.page = page
      this.fetchMerchantBusinessList()
    },
    changePage(value) {
      this.perPage = value
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
.dropdown .dropdown-menu {
  max-height: 80px;
}
</style>
